<template>
  <div class="page poet">
    <div class="container">
      <transition name="fade">
        <div class="mark1" v-if="exp" @click="hide"></div>
      </transition>
      <div id="left" :class="{exp:exp}">
        <div class="leftn">
          <div class="tan" @click="exp=!exp"></div>
          <div class="tags">
            <div class="httitle f20">标签</div>
            <div class="tagsList f14">
            <span @click="toggle('chooseTag',item)" v-for="(item,xh) in tags" :key="xh"
                  :class="{cur:chooseAll.chooseTag==item}">{{item}}</span>
            </div>
          </div>
          <div class="type">
            <div class="httitle f20">朝代</div>
            <ul class="f16">
              <li @click="toggle('chooseDynasty',item.title)" :class="{cur:chooseAll.chooseDynasty===item.title}"
                  v-for="(item,xh) in dynasty"
                  :key="xh">
                {{item.title}}<i v-if="getTotalNum(item.title)>0">{{getTotalNum(item.title)}}</i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="right">
        <div class="position f16">
          <span>{{chooseWhich}}</span>
          <div class="orderBy f14">
            <dl>
              <dt @click="px('poemNum')" :class="{cur:orderBy.curitem==='poemNum'}">作品数量
                <svg :class="{sup:orderBy.sortby['poemNum']==1,sdown:orderBy.sortby['poemNum']==0}" width="11"
                     height="16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <rect x="4.836" y="4.561" width="1.328" height="6.696" rx="0.2" fill="currentColor"></rect>
                  <path d="M2.795 5.94a.25.25 0 01-.176-.426l2.598-2.6a.4.4 0 01.566 0l2.598 2.6a.25.25 0 01-.176.426h-5.41z"
                        fill="currentColor"></path>
                </svg>
              </dt>
              <dt @click="px('live')" :class="{cur:orderBy.curitem==='live'}">出生时间
                <svg :class="{sup:orderBy.sortby['live']==1,sdown:orderBy.sortby['live']==0}" width="11" height="16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <rect x="4.836" y="4.561" width="1.328" height="6.696" rx="0.2" fill="currentColor"></rect>
                  <path d="M2.795 5.94a.25.25 0 01-.176-.426l2.598-2.6a.4.4 0 01.566 0l2.598 2.6a.25.25 0 01-.176.426h-5.41z"
                        fill="currentColor"></path>
                </svg>
              </dt>
            </dl>
          </div>
        </div>
        <ul class="list" v-if="poetFilter.length>0">
          <router-link tag="li" :to="'/poet/'+item.id" v-for="(item,xh) in poetFilter" :key="xh">
            <div class="poemT f24 ellipsis1">{{item.poet}}<i>{{getTotal(item.poet)}}</i></div>
            <div class="poetT ellipsis1 f14"><i @click.stop="chooseAll.chooseDynasty=item.dynasty">{{item.dynasty}}</i> |
              {{item.live}}
            </div>
            <div class="content ellipsis f16" v-html="jie(item.content)"></div>
          </router-link>
        </ul>
        <div class="pages" v-if="poetFilter.length>0">
          <dl class="f16">
            <dt @click="page=xh+1" v-for="(item,xh) in totalpage" :key="xh" :class="{cur:page==xh+1}">{{item}}</dt>
          </dl>
        </div>
        <div v-else class="nodata f24">没有数据</div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
    name: 'poem',
    components: {},
    data() {
        return {
            exp: false,
            dynasty: [],
            poem: [],
            poet: [],
            poetAll: [],//原始数据
            chooseAll: {
                chooseDynasty: '',
                chooseTag: '',
            },
            orderBy: {
                curitem: "",
                sortby: {'poemNum': 0, 'live': 0}, // 1 升序  0 降序
            },//这个把排序的选项弄到这边，动态watch
            page: 1,
            totalpage: 1,
            pageSize: 20,
        }
    },
    created() {

        let formData2 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'dynasty',
            perpage: 10000,
        }
        let formData3 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poem',
            perpage: 10000,
            order: ["id DESC"]
        }
        let formData4 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poet',
            perpage: 10000,
            order: ["id DESC"]
        }


        if (this.$store.state.articles.dynasty.length === 0) {
            this.$store.dispatch('articles/getList', formData2).then((rs) => {
                this.dynasty = rs

            })
        } else {
            this.dynasty = this.$store.state.articles.dynasty
        }
        if (this.$store.state.articles.poem.length === 0) {
            this.$store.dispatch('articles/getList', formData3).then((rs) => {
                this.poem = rs

            })
        } else {
            this.poem = this.$store.state.articles.poem
        }
        if (this.$store.state.articles.poet.length === 0) {
            this.$store.dispatch('articles/getList', formData4).then((rs) => {
                this.poet = rs
                this.poet.forEach((el) => {
                    this.$set(el, 'poemNum', this.getTotal(el.poet))
                })
                this.poetAll = Object.assign([], this.poet)

            })
        } else {
            this.poet = this.$store.state.articles.poet
            this.poet.forEach((el) => {
                this.$set(el, 'poemNum', this.getTotal(el.poet))
            })
            this.poetAll = Object.assign([], this.poet)
        }


    },
    mounted() {

    },
    methods: {
        hide() {
            this.exp = false
        },
        toggle(which, text) {
            if (this.chooseAll[which] && this.chooseAll[which] == text) {
                this.chooseAll[which] = ''
            } else {
                let k = Object.keys(this.chooseAll)
                k.forEach((el) => {
                    this.$set(this.chooseAll, el, '')
                    this.chooseAll[which] = text
                })

            }
        },
        px(el) {
            if (el != this.orderBy.curitem) {
                this.orderBy.curitem = el
            } else {
                if (this.orderBy.sortby[this.orderBy.curitem] == 1) {
                    this.orderBy.sortby[this.orderBy.curitem] = 0
                } else {
                    this.orderBy.sortby[this.orderBy.curitem] = 1
                }
            }
        },
        getTotal(text) {
            let fi = this.poem.filter((el) => {
                return el.poet === text
            })
            return fi.length
        },
        getTotalNum(text) {
            let fi = this.poet.filter((el) => {
                return el.dynasty === text
            })
            return fi.length
        },
        jie(html) {
            //let much
            let reg = new RegExp('<[^>]+>', 'gi');  //过滤所有的html标签，不包括内容
            if (typeof html != 'string') {  //不是字符串
                return html;
            }
            let last = html.replace(reg, '')
            if (window.innerWidth > 768) {
                //much = 60
            } else {
                //much = 20
            }

            //let lh = last.substr(0, much)
            return last
        }
    },
    computed: {
        chooseWhich() {
            let k = Object.keys(this.chooseAll)
            let fi = k.filter((el) => {
                return this.chooseAll[el]
            })
            return this.chooseAll[fi[0]]
        },
        poetFilter() {
            let lastData = []
            let fi = this.poet.filter((el) => {
                return el.dynasty.indexOf(this.chooseAll.chooseDynasty) > -1 && String(el.tags).indexOf(this.chooseAll.chooseTag) > -1
            })
            this.$set(this, 'totalpage', Math.ceil(fi.length / this.pageSize))
            lastData = fi.filter((el, index) => {
                return index >= (this.page - 1) * this.pageSize && index < this.page * this.pageSize
            })
            return lastData
        },
        tags() {
            let tags = []
            let tag = ''
            this.poetAll.forEach((el) => {
                if (el.tags) {
                    tag += el.tags + '|'
                }
            })
            tags = tag.split('|')
            tags.splice(tags.length - 1, 1)
            //tag出现次数方法
            const obj = tags.reduce((sum, item) => {
                sum[item] = sum[item] ? sum[item] += 1 : 1
                return sum
            }, {})
            let fi = Object.keys(obj).filter((el) => {
                return obj[el] > 1
            })


            //tags = new Set(tags)
            return fi
        }
    },
    watch: {
        chooseAll: {
            immediate: true,
            deep: true,
            handler() {
                this.page = 1
            }
        },
        orderBy: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value.sortby[value.curitem] == 1) {
                    this.poet.sort((a, b) => {
                        let value1 = a[value.curitem],
                            value2 = b[value.curitem];
                        if (value.curitem == "live") {
                            let v1 = value1.split('年')[0],
                                v2 = value2.split('年')[0]
                            let v11 = v1.replace('前', '-')
                            let v22 = v2.replace('前', '-')
                            let v111 = v11.replace(/[\u4e00-\u9fa5]/g, '')
                            let v222 = v22.replace(/[\u4e00-\u9fa5]/g, '')
                            return v111 - v222;

                        } else {
                            return value1 - value2;
                        }

                    });
                } else {
                    this.poet.sort((a, b) => {
                        let value1 = a[value.curitem],
                            value2 = b[value.curitem];
                        if (value.curitem == "live") {
                            let v1 = value1.split('年')[0],
                                v2 = value2.split('年')[0]
                            let v11 = v1.replace('前', '-')
                            let v22 = v2.replace('前', '-')
                            let v111 = v11.replace(/[\u4e00-\u9fa5]/g, '')
                            let v222 = v22.replace(/[\u4e00-\u9fa5]/g, '')
                            return v222 - v111;

                        } else {
                            return value2 - value1;
                        }

                    });
                }

            }
        },
    }
}
</script>

